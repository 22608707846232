import { template as template_a31b6f9761e1437abfe02e2dbd6ed25d } from "@ember/template-compiler";
const WelcomeHeader = template_a31b6f9761e1437abfe02e2dbd6ed25d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
