import { template as template_3604ef7a2ea649c593b6c33da7055b5c } from "@ember/template-compiler";
const FKText = template_3604ef7a2ea649c593b6c33da7055b5c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
